import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/SearchIcon.svg';
// import { ReactComponent as FleggsLogo } from '../../assets/Fleggs.svg';
// import BlackLogoFleggs from '../../assets/FullLogo.svg';
import { ReactComponent as BlackLogoFleggs } from '../../assets/FleggsNewLogo.svg';
import WhiteLogoFleggs from '../../assets/FullLogo.png';
import GreenLogoFleggs from '../../assets/logoNew.png';
import { ReactComponent as FleggsGreenSvg } from '../../assets/fleggsGreenSvg.svg';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
  STOCK_MULTIPLE_ITEMS,
} from '../../util/types';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
// import GreenLogoFleggs from '../../assets/newGreenLogo.png';

import { FormattedMessage, useIntl } from 'react-intl';
import css from './CustomTopbar.module.css';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  NotificationBadge,
  Button,
} from '../../components';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useConfiguration } from '../../context/configurationContext';
import AnimatePlaceholder from '../../components/animatePlaceholder/animatePlaceholder';

function CustomTopbar(props) {
  const {
    currentUser,
    onLogout,
    isAuthenticated,
    customAnimation,
    currentUserHasListings,
    notificationCount,
    listings,
  } = props;

  const id = listings[0]?.id?.uuid;
  const title = listings[0]?.attributes?.title;
  const slug = title && createSlug(title);
  const [currentIndex, setCurrentIndex] = useState(0);
  const firstName = currentUser?.attributes?.profile?.firstName;
  const lastName = currentUser?.attributes?.profile?.lastName;
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';
  const intl = useIntl();
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [mounted, setMounted] = useState(false);
  // const [searchParams, setSearchParams] = useState(null);
  // const [currentLetters, setCurrentLetters] = useState('');
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const serviceNames = config?.listing?.listingFields?.filter(f => f.key === 'servicesNames')?.[0]
    ?.enumOptions;
  const placeholdersArray = serviceNames.map(item => item.label);
  placeholdersArray.splice(placeholdersArray.length - 1, 1);

  const menuDiv = useRef(null);
  const menuButton = useRef(null);

  const isDraft = listings[0]?.attributes.state === LISTING_STATE_DRAFT;
  const isPendingApproval = listings[0]?.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps =
    isPendingApproval || isDraft
      ? {
          name: 'ListingPageVariant',
          params: {
            id,
            slug,
            variant,
          },
        }
      : {
          name: 'ListingPage',
          params: { id, slug },
        };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuDiv.current &&
        !menuDiv.current.contains(event.target) &&
        menuButton.current &&
        !menuButton.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !currentUser) {
      setMenuOpen(false);
    }
  }, [isAuthenticated, currentUser]);

  const authenticatedOnClientSide = mounted && isAuthenticated && currentUser;

  // const trainingText = 'Training';
  // const seacrhPlacehHolder = intl.formatMessage(
  //   { id: 'CustomTopbar.searchPlaceholder' },
  //   { currentLetters }
  // );

  const menu = !authenticatedOnClientSide ? (
    <div ref={menuDiv} className={css.menuDiv}>
      <NamedLink name="SignupPage" className={css.linkText}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="CustomTopbar.signup" />
        </span>
      </NamedLink>
      <NamedLink name="LoginPage" className={css.linkText}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="CustomTopbar.login" />
        </span>
      </NamedLink>
    </div>
  ) : (
    <div ref={menuDiv} className={css.menuDiv}>
      {currentUser?.attributes?.profile?.publicData?.isTrainer && (
        <NamedLink name="InboxPage" params={{ tab: 'sales' }} className={css.linkText}>
          <div className={css.inboxLink}>
            <span className={css.topbarLinkLabel}>My Bookings</span>
            {notificationDot}
          </div>
        </NamedLink>
      )}
      {
        <NamedLink name="InboxPage" params={{ tab: 'orders' }} className={css.linkText}>
          <div className={css.inboxLink}>
            <span className={css.topbarLinkLabel}>My Orders</span>
            {/* {notificationDot} */}
          </div>
        </NamedLink>
      }
      {currentUser?.attributes?.profile?.publicData?.isTrainer &&
        (currentUserHasListings ? (
          <NamedLink
            // className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            className={css.linkText}
            name={linkProps.name}
            params={linkProps.params}
          >
            <span className={css.topbarLinkLabel} />
            {/* <FormattedMessage id="TopbarDesktop.profileSettingsLink" /> */}
            My Listing
          </NamedLink>
        ) : (
          <span className={css.linkText}>My Listing</span>
        ))}
      {!currentUser?.attributes?.profile?.publicData?.isTrainer && !currentUserHasListings && (
        <NamedLink
          // className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
          className={css.linkText}
          name="ProfileSettingsPage"
        >
          <span className={css.topbarLinkLabel} />
          <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
        </NamedLink>
      )}
      <NamedLink name="AccountSettingsPage" className={css.linkText}>
        <span className={css.topbarLinkLabel}>Account Settings</span>
      </NamedLink>
      {!currentUser?.attributes?.profile?.publicData?.isTrainer && (
        <NamedLink name="FitnessGoalPage" className={css.linkText}>
          <span className={css.topbarLinkLabel}>Fitness Goal</span>
        </NamedLink>
      )}
      {/* <NamedLink name="InboxPage" params={{ tab: inboxTab }} className={css.linkText}>
        <span className={css.topbarLinkLabel}>Inbox</span>
      </NamedLink> */}
      {/* <InlineTextButton className={css.topbarLinkLabel} onClick={onLogout}> */}
      <span className={css.logoutLinkText} onClick={onLogout}>
        <FormattedMessage id="TopbarDesktop.logout" />
      </span>
      {/* </InlineTextButton> */}
    </div>
  );

  const profileMenu = authenticatedOnClientSide ? (
    <Menu className={css.profileMenuContainer}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar
          className={css.avatar}
          user={currentUser}
          disableProfileLink
          showDefaultProfile={false}
        />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;
  return (
    <>
      {customAnimation ? (
        <div className={`${css.customNavbar} ${scrolled ? `${css.customNavbarscrolled}` : ``}`}>
          <div className={css.topbarMddle}>
            <div className={css.searchDiv}>
              <SearchIcon />
              {/* <input
                type="text"
                placeholder={seacrhPlacehHolder}
                name="seacrhInput"
                onChange={e => {
                  setSearchParams(e.target.value);
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    const element = serviceNames.find(
                      item => item.label === searchParams || item.option === searchParams
                    );
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration,
                        {},
                        { pub_servicesNames: `has_any:${element?.label},${element?.option}` }
                      )
                    );
                  }
                }}
              /> */}
              <AnimatePlaceholder options={serviceNames} />
            </div>
            <div className={css.logoDiv}>
              {/* <FleggsLogo /> */}
              <NamedLink name="LandingPage">
                {/* <img src={GreenLogoFleggs} alt="FullImg" /> */}
              </NamedLink>
              {scrolled ? (
                <NamedLink name="LandingPage">
                  {/* <img src={BlackLogoFleggs} alt="FullImg" /> */}
                  <BlackLogoFleggs />
                </NamedLink>
              ) : (
                <NamedLink name="LandingPage">
                  <BlackLogoFleggs className={css.whiteLogo} />
                  {/* <img src={WhiteLogoFleggs} alt="FullImg" /> */}
                </NamedLink>
              )}
            </div>
            <div className={css.joinDiv}>
              {currentUser && currentUser?.attributes?.profile?.publicData?.isTrainer ? (
                <NamedLink
                  name="NewListingEventPage"
                  className={classNames(css.listingLinkText, css.highlight)}
                >
                  <span className={css.priorityLinkLabel}>
                    {/* <FormattedMessage id="CustomTopbar.createListing" /> */}
                    Events
                  </span>
                </NamedLink>
              ) : null}

              {currentUser && currentUser?.attributes?.profile?.publicData?.isTrainer ? (
                <NamedLink
                  name="NewListingPage"
                  className={classNames(css.listingLinkText, css.highlight)}
                >
                  <span className={css.priorityLinkLabel}>
                    <FormattedMessage id="CustomTopbar.createListing" />
                  </span>
                </NamedLink>
              ) : null}

              {currentUser && isAuthenticated ? (
                <button
                  className={css.joinSignIn}
                  onClick={() => {
                    setMenuOpen(p => !p);
                  }}
                  ref={menuButton}
                >
                  <div className={css.notificationParentDiv}>
                    {firstName} {lastName}
                    {notificationDot}
                  </div>
                </button>
              ) : (
                <div className={css.signLogInDiv}>
                  <div className={css.signLogIn}>
                    <NamedLink name="SignupPage">
                      <FormattedMessage id="CustomTopbar.signup" />
                    </NamedLink>
                  </div>
                  <div className={css.signLogIn}>
                    <NamedLink name="LoginPage">
                      <FormattedMessage id="CustomTopbar.login" />
                    </NamedLink>
                  </div>
                </div>
              )}

              {/* <button
                className={css.joinSignIn}
                onClick={() => {
                  setMenuOpen(p => !p);
                }}
                ref={menuButton}
              >
                {currentUser && isAuthenticated ? (
                  <div className={css.notificationParentDiv}>
                    {firstName} {lastName}
                    {notificationDot}
                  </div>
                ) : (
                  <FormattedMessage id="CustomTopbar.joinSignIn" />
                )}
              </button> */}
              {menuOpen ? menu : null}

              {/* <NamedLink name="SignupPage" className={css.linkText}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="CustomTopbar.signup" />
          </span>
        </NamedLink> */}
            </div>
          </div>
        </div>
      ) : (
        // <div
        //   className={`${css.customNavbar} ${
        //     scrolled ? `${css.customNavbarscrolled}` : `${css.customNavbarMain}`
        //   }`}
        // >

        <div className={`${css.customNavbarMain}`}>
          <div className={css.topbarMddle}>
            <div className={css.searchDiv}>
              <SearchIcon />
              {/* <input
                type="text"
                placeholder={seacrhPlacehHolder}
                name="seacrhInput"
                onChange={e => {
                  setSearchParams(e.target.value);
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    const element = serviceNames.find(
                      item => item.label === searchParams || item.option === searchParams
                    );
                    history.push(
                      createResourceLocatorString(
                        'SearchPage',
                        routeConfiguration,
                        {},
                        { pub_servicesNames: `has_any:${element?.label},${element?.option}` }
                      )
                    );
                  }
                }}
              /> */}
              <AnimatePlaceholder options={serviceNames} />
            </div>
            <div className={css.customLogoDiv}>
              {/* <FleggsLogo /> */}

              <NamedLink name="LandingPage">
                {/* <img src={GreenLogoFleggs} alt="FullImg" /> */}
                <FleggsGreenSvg />
                {/* <img src={BlackLogoFleggs} alt="FullImg" /> */}
                {/* <img src={GreenLogoFleggs} alt="FullImg" /> */}
              </NamedLink>
            </div>
            <div className={css.joinDiv}>
              {currentUser && currentUser?.attributes?.profile?.publicData?.isTrainer && (
                <NamedLink
                  name="NewListingEventPage"
                  className={classNames(css.listingLinkText, css.highlight)}
                >
                  <span className={css.priorityLinkLabel}>
                    {/* <FormattedMessage id="CustomTopbar.createListing" /> */}
                    Events
                  </span>
                </NamedLink>
              )}
              {currentUser && currentUser?.attributes?.profile?.publicData?.isTrainer && (
                <NamedLink
                  name="NewListingPage"
                  className={classNames(css.listingLinkText, css.highlight)}
                >
                  <span className={css.priorityLinkLabel}>
                    <FormattedMessage id="CustomTopbar.createListing" />
                  </span>
                </NamedLink>
              )}
              {currentUser && isAuthenticated ? (
                <button
                  className={css.joinSignIn}
                  onClick={() => {
                    setMenuOpen(p => !p);
                  }}
                  ref={menuButton}
                >
                  <div className={css.notificationParentDiv}>
                    {firstName} {lastName}
                    {notificationDot}
                  </div>
                </button>
              ) : (
                <div className={css.signLogInDiv}>
                  <div className={css.signLogIn}>
                    <NamedLink name="SignupPage">
                      <FormattedMessage id="CustomTopbar.signup" />
                    </NamedLink>
                  </div>
                  <div className={css.signLogIn}>
                    <NamedLink name="LoginPage">
                      <FormattedMessage id="CustomTopbar.login" />
                    </NamedLink>
                  </div>
                </div>
              )}
              {/* <button
                className={css.joinSignIn}
                onClick={() => {
                  setMenuOpen(p => !p);
                }}
                ref={menuButton}
              >
                {currentUser && isAuthenticated ? (
                  <div className={css.notificationParentDiv}>
                    {firstName} {lastName}
                    {notificationDot}
                  </div>
                ) : (
                  <FormattedMessage id="CustomTopbar.joinSignIn" />
                )}
              </button> */}
              {menuOpen ? menu : null}

              {/* <NamedLink name="SignupPage" className={css.linkText}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="CustomTopbar.signup" />
        </span>
      </NamedLink> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = state => {
  const { currentUser, currentUserHasListings } = state.user;
  const { isAuthenticated } = state.auth;
  return { currentUser, isAuthenticated, currentUserHasListings };
};

export default compose(withRouter, connect(mapStateToProps))(CustomTopbar);
