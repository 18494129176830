import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';
import { parse } from '../../util/urlHelpers';
const MIN_ANIMATION_DELAY = 400;
const MAX_ANIMATION_DELAY = 500;

const AnimatePlaceholder = props => {
  const { options, history, location } = props;
  const search = parse(location?.search);
  const [searchParams, setSearchParams] = useState(search?.keywords || '');
  const [currentLetters, setCurrentLetters] = useState('');
  // const optionPlaceholder = options.map(item => item.label);
  // optionPlaceholder.splice(optionPlaceholder.length - 1, 1);
  const optionPlaceholder = ['Personal Training', 'Yoga', 'Pilates', 'Wellbeing'];
  let trainingText = [...optionPlaceholder];
  const intl = useIntl();
  const seacrhPlacehHolder = intl.formatMessage(
    { id: 'CustomTopbar.searchPlaceholder' },
    { currentLetters }
  );
  const routeConfiguration = useRouteConfiguration();

  const getRandomDelayBetween = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  let index = 0;
  // const animatePlaceholder = placeholder => {
  //   if (placeholder[index] === '') {
  //     setCurrentLetters('');
  //     index++;
  //   }
  //   if (index === placeholder.length) {
  //     setCurrentLetters('');
  //     placeholder = [...optionPlaceholder];
  //     trainingText = [...optionPlaceholder];
  //     index = 0;
  //   }
  //   const firstLetter = placeholder[index]?.charAt(0);
  //   const modifiedText = placeholder[index]?.substring(1);
  //   setCurrentLetters(p => p.concat(firstLetter));
  //   trainingText.splice(index, 1);
  //   trainingText.splice(index, 0, modifiedText);
  // };
  const animatePlaceholder = () => {
    if (index === trainingText.length) index = 0;
    setCurrentLetters(trainingText[index]);
    index++;
  };

  useEffect(() => {
    const animationTimedOut = setInterval(
      () => animatePlaceholder(trainingText),
      500
      // getRandomDelayBetween(MIN_ANIMATION_DELAY, MAX_ANIMATION_DELAY)
    );
    return () => clearInterval(animationTimedOut);
  }, []);

  return (
    <>
      <input
        type="text"
        placeholder={seacrhPlacehHolder}
        name="seacrhInput"
        value={searchParams}
        onChange={e => {
          setSearchParams(e.target.value);
        }}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            const value = e.target.value;
            if (!value) {
              let queryP = { ...search };
              if (queryP?.keywords) delete queryP['keywords'];
              return history.push(
                createResourceLocatorString('SearchPage', routeConfiguration, {}, { ...queryP })
              );
            }
            const query = { ...search, keywords: value };
            history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, query));
          }
        }}
      />
    </>
  );
};

export default compose(withRouter)(AnimatePlaceholder);
